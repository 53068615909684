import { type SQL, relations, sql } from 'drizzle-orm'
import {
  bigint,
  boolean,
  customType,
  date,
  foreignKey,
  index,
  integer,
  pgSchema,
  pgTable,
  primaryKey,
  text,
  timestamp,
  unique
} from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'
import { cleanCategoryName, generateCode, generateId } from './utils/helpers'

/**** Schemas ****/

export const authSchema = pgSchema('auth')

/**** Shared Columns ****/

const createdAt = timestamp('created_at', { mode: 'date', withTimezone: true })
  .notNull()
  .defaultNow()

const updatedAt = timestamp('updated_at', { mode: 'date', withTimezone: true })
  .notNull()
  .defaultNow()
  .$onUpdate(() => new Date())

/**** Custom Types ****/

export const customJsonb = <T extends z.ZodTypeAny>(
  name: string,
  schema: T
) => {
  return customType<{ data: z.infer<T>; driverData: string }>({
    dataType() {
      return 'jsonb'
    },
    toDriver(value: z.infer<T>): string {
      return JSON.stringify(schema.parse(value))
    },
    fromDriver(value: unknown) {
      return schema.parse(value) as z.infer<T>
    }
  })(name)
}

export const customEnum = <K extends string, T extends z.ZodEnum<[K, ...K[]]>>(
  name: string,
  schema: T
) => {
  return customType<{ data: z.infer<T>; driverData: string }>({
    dataType() {
      return 'text'
    },
    toDriver(value: z.infer<T>): string {
      const safeValue = schema.parse(value)
      return safeValue
    },
    fromDriver(value: string) {
      return schema.parse(value) as z.infer<T>
    }
  })(name)
}

/**** #Enums ****/

export const AuthProvidersEnum = z.enum(['google', 'microsoft'])

export const ExtensionStatusEnum = z.enum([
  'active',
  'inactive',
  'uninstalled',
  'never_installed'
])

export const UserRoleEnum = z.enum(['admin', 'restricted'])

export const SessionSourceEnum = z.enum(['extension', 'google'])

/**** #Auth Sessions ****/

export const AuthSessions = authSchema.table('sessions', {
  id: text('id')
    .primaryKey()
    .notNull()
    .$defaultFn(() => generateId()),
  userId: text('user_id')
    .notNull()
    .unique()
    .references(() => Users.id, { onDelete: 'cascade' }),
  expiresAt: timestamp('expires_at', {
    mode: 'date',
    withTimezone: true
  }).notNull(),
  createdAt: createdAt
})

export type AuthSession = typeof AuthSessions.$inferSelect
export type AuthSessionInsert = typeof AuthSessions.$inferInsert

export const authSessionRelations = relations(AuthSessions, ({ one }) => ({
  user: one(Users, {
    fields: [AuthSessions.userId],
    references: [Users.id]
  })
}))

export const insertAuthSessionSchema = createInsertSchema(AuthSessions)
export const selectAuthSessionSchema = createSelectSchema(AuthSessions)

/**** #Auth Providers ****/

export const AuthProviders = authSchema.table(
  'providers',
  {
    provider: customEnum('provider', AuthProvidersEnum).notNull(),
    providerId: text('provider_id').notNull(),
    userId: text('user_id')
      .notNull()
      .references(() => Users.id, { onDelete: 'cascade' }),
    createdAt: createdAt
  },
  (table) => ({
    primaryKey: primaryKey({ columns: [table.provider, table.providerId] })
  })
)

export type AuthProvider = typeof AuthProviders.$inferSelect
export type AuthProviderInsert = typeof AuthProviders.$inferInsert

/**** #Auth Domains ****/

export const AuthDomains = authSchema.table('domains', {
  id: integer('id').primaryKey().generatedAlwaysAsIdentity(),
  domain: text('domain').unique().notNull(),
  orgId: text('org_id')
    .notNull()
    .references(() => Organizations.id, { onDelete: 'cascade' }),
  verified: boolean('verified').notNull().default(false),
  code: integer('code')
    .notNull()
    .$defaultFn(() => generateCode(6))
})

export type AuthDomain = typeof AuthDomains.$inferSelect
export type AuthDomainInsert = typeof AuthDomains.$inferInsert

export const authDomainRelations = relations(AuthDomains, ({ one }) => ({
  organization: one(Organizations, {
    fields: [AuthDomains.orgId],
    references: [Organizations.id]
  })
}))

export const insertAuthDomainSchema = createInsertSchema(AuthDomains)
export const selectAuthDomainSchema = createSelectSchema(AuthDomains, {
  id: z.coerce.number(),
  code: z.coerce.number()
})

/**** #Auth Invitations ****/

export const AuthInvitations = authSchema.table('invitations', {
  id: text('id')
    .primaryKey()
    .notNull()
    .$defaultFn(() => generateId()),
  roleId: integer('role_id')
    .notNull()
    .references(() => Roles.id, { onDelete: 'cascade' }),
  orgId: text('org_id')
    .notNull()
    .references(() => Organizations.id, { onDelete: 'cascade' }),
  createdAt: createdAt
})

export type AuthInvitation = typeof AuthInvitations.$inferSelect
export type AuthInvitationInsert = typeof AuthInvitations.$inferInsert

export const authInvitationRelations = relations(
  AuthInvitations,
  ({ one }) => ({
    organization: one(Organizations, {
      fields: [AuthInvitations.orgId],
      references: [Organizations.id]
    }),
    role: one(Roles, {
      fields: [AuthInvitations.roleId],
      references: [Roles.id]
    })
  })
)

export const insertInvitationSchema = createInsertSchema(AuthInvitations)
export const selectInvitationSchema = createSelectSchema(AuthInvitations)

/**** #Auth Blacklist ****/

export const Blacklist = authSchema.table('blacklist', {
  domain: text('domain').primaryKey().notNull()
})

export type AuthBlacklist = typeof Blacklist.$inferSelect
export type AuthBlacklistInsert = typeof Blacklist.$inferInsert

/**** #Users ****/

export const Users = pgTable('users', {
  id: text('id')
    .primaryKey()
    .$defaultFn(() => generateId(12)),
  email: text('email').notNull().unique(),
  firstName: text('first_name').notNull(),
  lastName: text('last_name'),
  displayName: text('display_name')
    .notNull()
    .generatedAlwaysAs(
      (): SQL =>
        sql`${Users.firstName} || ' ' || COALESCE(SUBSTRING(${Users.lastName}, 1, 1), '')`
    ),
  fullName: text('full_name')
    .notNull()
    .generatedAlwaysAs(
      (): SQL => sql`${Users.firstName} || ' ' || ${Users.lastName}`
    ),
  avatarUrl: text('avatar_url'),
  lastSignInAt: timestamp('last_sign_in_at', {
    mode: 'date',
    withTimezone: true
  }),
  extensionStatus: customEnum('extension_status', ExtensionStatusEnum)
    .notNull()
    .default('never_installed'),
  isOnboarded: boolean('is_onboarded').notNull().default(false),
  isInternal: boolean('is_internal')
    .notNull()
    .generatedAlwaysAs((): SQL => sql`${Users.email} LIKE '%@hapstack.com'`),
  createdAt: createdAt,
  updatedAt: updatedAt
})

export type User = typeof Users.$inferSelect
export type UserInsert = typeof Users.$inferInsert

export const userRelations = relations(Users, ({ many }) => ({
  memberships: many(Memberships)
}))

export const insertUserSchema = createInsertSchema(Users)
export const selectUserSchema = createSelectSchema(Users, {
  extensionStatus: ExtensionStatusEnum
})

/**** #Roles ****/

export const Roles = pgTable('roles', {
  id: integer('id').primaryKey().generatedAlwaysAsIdentity(),
  role: customEnum('role', UserRoleEnum).notNull().unique()
})

export type Role = typeof Roles.$inferSelect
export type UserRole = z.infer<typeof UserRoleEnum>
export type RoleInsert = typeof Roles.$inferInsert

export const insertRoleSchema = createInsertSchema(Roles, {
  role: UserRoleEnum
})
export const selectRoleSchema = createSelectSchema(Roles, {
  role: UserRoleEnum
})

/**** #Organizations ****/

export const OrganizationSettingsSchema = z.object({
  automaticInvites: z.boolean().default(true)
})

export const Organizations = pgTable('organizations', {
  id: text('id')
    .primaryKey()
    .$defaultFn(() => generateId(12)),
  name: text('name').notNull(),
  createdBy: text('created_by').references(() => Users.id, {
    onDelete: 'set null'
  }),
  imageUrl: text('image_url'),
  settings: customJsonb('settings', OrganizationSettingsSchema)
    .notNull()
    .default({
      automaticInvites: true
    }),
  createdAt: createdAt,
  updatedAt: updatedAt
})

export type Organization = typeof Organizations.$inferSelect
export type OrganizationInsert = typeof Organizations.$inferInsert

export const OrganizationRelations = relations(
  Organizations,
  ({ one, many }) => ({
    creator: one(Users, {
      fields: [Organizations.createdBy],
      references: [Users.id]
    }),
    domains: many(AuthDomains),
    groups: many(Groups),
    invitations: many(AuthInvitations),
    memberships: many(Memberships),
    tags: many(OrganizationTags)
  })
)

export const insertOrganizationSchema = createInsertSchema(Organizations)
export const selectOrganizationSchema = createSelectSchema(Organizations)

/**** #Memberships ****/

export const Memberships = pgTable(
  'memberships',
  {
    orgId: text('org_id')
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    userId: text('user_id')
      .notNull()
      .references(() => Users.id, { onDelete: 'cascade' }),
    roleId: integer('role_id')
      .notNull()
      .references(() => Roles.id, { onDelete: 'restrict' }),
    isActive: boolean('is_active').notNull().default(true),
    createdAt: createdAt
  },
  (table) => {
    return {
      membershipId: primaryKey({
        columns: [table.orgId, table.userId]
      })
    }
  }
)

export type Membership = typeof Memberships.$inferSelect
export type MembershipInsert = typeof Memberships.$inferInsert

export const membershipRelations = relations(Memberships, ({ one, many }) => ({
  groupMemberships: many(GroupMemberships),
  organization: one(Organizations, {
    fields: [Memberships.orgId],
    references: [Organizations.id]
  }),
  role: one(Roles, {
    fields: [Memberships.roleId],
    references: [Roles.id]
  }),
  user: one(Users, {
    fields: [Memberships.userId],
    references: [Users.id]
  })
}))

export const insertMembershipSchema = createInsertSchema(Memberships)
export const selectMembershipSchema = createSelectSchema(Memberships)

/**** #Groups ****/

export const Groups = pgTable(
  'groups',
  {
    id: text('id')
      .primaryKey()
      .$defaultFn(() => generateId(12)),
    orgId: text('org_id')
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    name: text('name').notNull(),
    description: text('description'),
    createdAt: createdAt,
    updatedAt: updatedAt
  },
  (table) => ({
    uniqueGroups: unique().on(table.orgId, table.name)
  })
)

export type Group = typeof Groups.$inferSelect
export type GroupInsert = typeof Groups.$inferInsert

export const groupRelations = relations(Groups, ({ one, many }) => ({
  members: many(GroupMemberships),
  organization: one(Organizations, {
    fields: [Groups.orgId],
    references: [Organizations.id]
  })
}))

export const insertGroupSchema = createInsertSchema(Groups)
export const selectGroupSchema = createSelectSchema(Groups, {
  name: ({ name }) => name.trim().min(1, 'This field is required.')
})

/**** #Group Memberships ****/

export const GroupMemberships = pgTable(
  'group_memberships',
  {
    groupId: text('group_id')
      .notNull()
      .references(() => Groups.id, { onDelete: 'cascade' }),
    userId: text('user_id').notNull(),
    orgId: text('org_id').notNull(),
    createdAt: createdAt
  },
  (table) => ({
    membership: primaryKey({
      columns: [table.groupId, table.userId, table.orgId]
    }),
    membershipId: foreignKey({
      columns: [table.orgId, table.userId],
      foreignColumns: [Memberships.orgId, Memberships.userId]
    }).onDelete('cascade')
  })
)

export type GroupMembership = typeof GroupMemberships.$inferSelect
export type GroupMembershipInsert = typeof GroupMemberships.$inferInsert

export const groupMembershipRelations = relations(
  GroupMemberships,
  ({ one }) => ({
    group: one(Groups, {
      fields: [GroupMemberships.groupId],
      references: [Groups.id]
    })
  })
)

export const insertGroupMembershipSchema = createInsertSchema(GroupMemberships)
export const selectGroupMembershipSchema = createSelectSchema(GroupMemberships)

/**** #Vendors ****/

export const Vendors = pgTable(
  'vendors',
  {
    id: text('id')
      .primaryKey()
      .$defaultFn(() => generateId(8)),
    name: text('name').notNull(),
    tagline: text('tagline').notNull(),
    iconUrl: text('icon_url').notNull(),
    website: text('website').notNull(),
    originalId: integer('original_id').unique(),
    createdAt: createdAt,
    updatedAt: updatedAt
  },
  (table) => ({
    vendorsNameIdx: index('vendors_name_trgm_index').using(
      'gin',
      sql`${table.name} gin_trgm_ops`
    )
  })
)

export type Vendor = typeof Vendors.$inferSelect
export type VendorInsert = typeof Vendors.$inferInsert

export const vendorRelations = relations(Vendors, ({ many }) => ({
  products: many(Products),
  tags: many(VendorTags)
}))

export const insertVendorSchema = createInsertSchema(Vendors, {
  iconUrl: (schema) => schema.iconUrl.url(),
  website: (schema) => schema.website.url(),
  name: (schema) => schema.name.trim().min(1),
  tagline: (schema) => schema.tagline.trim().min(1)
})
export const selectVendorSchema = createSelectSchema(Vendors, {
  iconUrl: (schema) => schema.iconUrl.url(),
  website: (schema) => schema.website.url()
})

/**** #Tags ****/

export const Tags = pgTable('tags', {
  id: integer('id').primaryKey().generatedAlwaysAsIdentity(),
  name: text('name').unique().notNull(),
  isDefault: boolean('is_default').notNull().default(false),
  createdAt: createdAt
})

export type Tag = typeof Tags.$inferSelect
export type TagInsert = typeof Tags.$inferInsert

export const insertTagSchema = createInsertSchema(Tags)
export const selectTagSchema = createSelectSchema(Tags)

/**** #Tag Colors ****/

export const TagColors = pgTable('tag_colors', {
  id: integer('id').primaryKey().generatedAlwaysAsIdentity(),
  backgroundColor: text('background_color').notNull(),
  textColor: text('text_color').notNull(),
  name: text('name').notNull().unique()
})

export type TagColor = typeof TagColors.$inferSelect
export type TagColorInsert = typeof TagColors.$inferInsert

export const insertTagColorSchema = createInsertSchema(TagColors)
export const selectTagColorSchema = createSelectSchema(TagColors)

/**** #Organization Tags ****/

export const OrganizationTags = pgTable(
  'organization_tags',
  {
    orgId: text('org_id')
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    tagId: integer('tag_id')
      .notNull()
      .references(() => Tags.id, { onDelete: 'cascade' }),
    colorId: integer('color_id')
      .notNull()
      .references(() => TagColors.id, {
        onDelete: 'restrict'
      })
  },
  (table) => ({
    organizationTag: primaryKey({
      columns: [table.orgId, table.tagId]
    })
  })
)

export type OrganizationTag = typeof OrganizationTags.$inferSelect
export type OrganizationTagInsert = typeof OrganizationTags.$inferInsert

export const organizationTagRelations = relations(
  OrganizationTags,
  ({ one }) => ({
    color: one(TagColors, {
      fields: [OrganizationTags.colorId],
      references: [TagColors.id]
    }),
    organization: one(Organizations, {
      fields: [OrganizationTags.orgId],
      references: [Organizations.id]
    }),
    tag: one(Tags, {
      fields: [OrganizationTags.tagId],
      references: [Tags.id]
    })
  })
)

export const insertOrganizationTagSchema = createInsertSchema(OrganizationTags)
export const selectOrganizationTagSchema = createSelectSchema(OrganizationTags)

// /**** #Vendor Tags ****/

export const VendorTags = pgTable(
  'vendor_tags',
  {
    vendorId: text('vendor_id')
      .notNull()
      .references(() => Vendors.id, { onDelete: 'cascade' }),
    tagId: integer('tag_id')
      .notNull()
      .references(() => Tags.id, { onDelete: 'cascade' }),
    orgId: text('org_id')
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' })
  },
  (table) => ({
    orgTagReference: foreignKey({
      columns: [table.orgId, table.tagId],
      foreignColumns: [OrganizationTags.orgId, OrganizationTags.tagId]
    }).onDelete('cascade'),
    vendorTag: primaryKey({
      columns: [table.vendorId, table.tagId, table.orgId]
    })
  })
)

export type VendorTag = typeof VendorTags.$inferSelect
export type VendorTagInsert = typeof VendorTags.$inferInsert

export const vendorTagRelations = relations(VendorTags, ({ one }) => ({
  vendor: one(Vendors, {
    fields: [VendorTags.vendorId],
    references: [Vendors.id]
  }),
  tag: one(Tags, {
    fields: [VendorTags.tagId],
    references: [Tags.id]
  }),
  organization: one(Organizations, {
    fields: [VendorTags.orgId],
    references: [Organizations.id]
  })
}))

export const insertVendorTagSchema = createInsertSchema(VendorTags)
export const selectVendorTagSchema = createSelectSchema(VendorTags)

/**** #Favorited Vendors ****/

export const FavoritedVendors = pgTable(
  'favorited_vendors',
  {
    orgId: text('org_id')
      .notNull()
      .references(() => Organizations.id, { onDelete: 'cascade' }),
    vendorId: text('vendor_id')
      .notNull()
      .references(() => Vendors.id, { onDelete: 'cascade' }),
    createdAt: createdAt
  },
  (table) => ({
    favoritedVendor: primaryKey({
      columns: [table.orgId, table.vendorId]
    })
  })
)

export type FavoritedVendor = typeof FavoritedVendors.$inferSelect
export type FavoritedVendorInsert = typeof FavoritedVendors.$inferInsert

export const favoritedVendorRelations = relations(
  FavoritedVendors,
  ({ one }) => ({
    organzation: one(Organizations, {
      fields: [FavoritedVendors.orgId],
      references: [Organizations.id]
    }),
    vendor: one(Vendors, {
      fields: [FavoritedVendors.vendorId],
      references: [Vendors.id]
    })
  })
)

/**** #Products ****/

export const Products = pgTable(
  'products',
  {
    id: text('id')
      .primaryKey()
      .$defaultFn(() => generateId(10)),
    name: text('name').notNull(),
    iconUrl: text('icon_url'),
    vendorId: text('vendor_id')
      .notNull()
      .references(() => Vendors.id, { onDelete: 'cascade' }),
    originalVendorId: integer('original_vendor_id'),
    createdAt: createdAt,
    updatedAt: updatedAt
  },
  (table) => ({
    uniqueProduct: unique().on(table.vendorId, table.name),
    vendorIdIdx: index().on(table.vendorId)
  })
)

export type Product = typeof Products.$inferSelect
export type ProductInsert = typeof Products.$inferInsert

export const productRelations = relations(Products, ({ one, many }) => ({
  productCategories: many(ProductCategories),
  urls: many(Urls),
  vendor: one(Vendors, {
    fields: [Products.vendorId],
    references: [Vendors.id]
  })
}))

export const insertProductSchema = createInsertSchema(Products, {
  iconUrl: (schema) => schema.iconUrl.url()
})
export const selectProductSchema = createSelectSchema(Products)

/**** #Urls ****/

export const Urls = pgTable(
  'urls',
  {
    id: text('id')
      .primaryKey()
      .$defaultFn(() => generateId()),
    productId: text('product_id')
      .notNull()
      .references(() => Products.id, {
        onDelete: 'cascade'
      }),
    subdomainPattern: text('subdomain_pattern').notNull().default('.*'),
    domain: text('domain').notNull(),
    tldPattern: text('tld_pattern').notNull(),
    pathPattern: text('path_pattern').notNull().default('/*'),
    notes: text('notes'),
    createdAt: createdAt,
    updatedAt: updatedAt
  },
  (table) => ({
    uniqueUrl: unique().on(
      table.productId,
      table.subdomainPattern,
      table.domain,
      table.pathPattern
    ),
    productIdIdx: index().on(table.productId)
  })
)

export type Url = typeof Urls.$inferSelect
export type UrlInsert = typeof Urls.$inferInsert

export const urlRelations = relations(Urls, ({ one }) => ({
  product: one(Products, {
    fields: [Urls.productId],
    references: [Products.id]
  })
}))

export const insertUrlSchema = createInsertSchema(Urls)
export const selectUrlSchema = createSelectSchema(Urls)

/**** #Sessions ****/

export const Sessions = pgTable(
  'sessions',
  {
    id: bigint('id', { mode: 'number' })
      .primaryKey()
      .generatedAlwaysAsIdentity(),
    userId: text('user_id')
      .notNull()
      .references(() => Users.id, { onDelete: 'cascade' }),
    urlId: text('url_id')
      .references(() => Urls.id, { onDelete: 'cascade' })
      .notNull(),
    date: date('date', { mode: 'date' }).notNull(),
    pageviews: integer('pageviews').notNull().default(1),
    url: text('url').notNull(),
    source: customEnum('source', SessionSourceEnum).notNull(),
    createdAt: createdAt,
    updatedAt: updatedAt
  },
  (table) => ({
    uniqueSession: unique().on(table.userId, table.urlId, table.date),
    userIdIdx: index().on(table.userId),
    urlIdIdx: index().on(table.urlId)
  })
)

export type Session = typeof Sessions.$inferSelect
export type SessionInsert = typeof Sessions.$inferInsert

export const sessionRelations = relations(Sessions, ({ one }) => ({
  user: one(Users, {
    fields: [Sessions.userId],
    references: [Users.id]
  }),
  url: one(Urls, {
    fields: [Sessions.urlId],
    references: [Urls.id]
  })
}))

export const insertSessionSchema = createInsertSchema(Sessions)
export const selectSessionSchema = createSelectSchema(Sessions, {
  source: SessionSourceEnum
})

/**** #Categories ****/

export const Categories = pgTable(
  'categories',
  {
    id: integer('id').primaryKey().generatedAlwaysAsIdentity(),
    name: text('name').notNull(),
    slug: text('slug').unique(),
    parentId: integer('parent_id'),
    createdAt: createdAt,
    updatedAt: updatedAt
  },
  (table) => {
    return {
      parentReference: foreignKey({
        columns: [table.parentId],
        foreignColumns: [table.id]
      }),
      uniqueCategory: unique().on(table.name, table.parentId)
    }
  }
)

export type Category = typeof Categories.$inferSelect
export type CategoryInsert = typeof Categories.$inferInsert

export const categoriesRelations = relations(Categories, ({ one, many }) => ({
  parent: one(Categories, {
    fields: [Categories.parentId],
    references: [Categories.id],
    relationName: 'parent'
  }),
  productCategories: many(ProductCategories),
  subcategories: many(Categories, { relationName: 'subcategories' })
}))

export const insertCategorySchema = createInsertSchema(Categories)
export const selectCategorySchema = createSelectSchema(Categories, {
  id: z.coerce.number(),
  name: (schema) => schema.name.transform(cleanCategoryName)
})

/**** #Product Categories ****/

export const ProductCategories = pgTable(
  'product_categories',
  {
    productId: text('product_id')
      .notNull()
      .references(() => Products.id, { onDelete: 'cascade' }),
    categoryId: integer('category_id')
      .notNull()
      .references(() => Categories.id, { onDelete: 'cascade' }),
    createdAt: createdAt
  },
  (table) => ({
    primaryKey: primaryKey({ columns: [table.productId, table.categoryId] })
  })
)

export type ProductCategory = typeof ProductCategories.$inferSelect
export type ProductCategoryInsert = typeof ProductCategories.$inferInsert

export const productCategoryRelations = relations(
  ProductCategories,
  ({ one }) => ({
    product: one(Products, {
      fields: [ProductCategories.productId],
      references: [Products.id]
    }),
    category: one(Categories, {
      fields: [ProductCategories.categoryId],
      references: [Categories.id]
    })
  })
)

export const insertProductCategorySchema = createInsertSchema(ProductCategories)
export const selectProductCategorySchema = createSelectSchema(ProductCategories)
